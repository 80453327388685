import './Roadmap.css';
export default function Roadmap(props){
    return (<div className="roadmap">

        <ul className="roadmap-circle">


            <li className="roadmap-item">
                <div className="oval-shape" style={{border: '3px solid #6de6b7'}}>
                    <span>1</span>
                </div>
                <div className="roadmap-desc roadmap-desc-front">
                    <h1 className="text-green-300">THE CLUB</h1>
                    <p>The EGO Fox team will be completely invested in growing a solid community. Together, with our earliest supporters, we will work on the development of the EGO Fox future and all it’s creative potential! Delivering amazing art to the members who believe in our project is our main source of inspiration to work hard at creating a long lasting and profitable project to all holders!</p>
                </div>
            </li>

            <li className="roadmap-item">
                <div className="oval-shape" style={{border: '3px solid #a5b4fb'}}>
                    <span>2</span>
                </div>
                <div className="roadmap-desc roadmap-desc-front">
                    <h1 className="text-indigo-300">THE BEGINNING</h1>
                    <p className="bullet-item">Keep an eye out for minting a Fox with a tattoo, a total of ten (10) EGO Fox’s will have a tattoo and if you mint one, you will be eligible to claim a tattoo session with the amazing creator of our Foxes (more info on transferability & period of validity to come once you claim). Also holders of 10+ Foxes will receive 40% discount for life if they wish to get tattooed by Eig. Holders of less than 10 Foxes receive 10%. For reference you can check out the artist’s work on his IG portfolio @eig.tattoo. Each session given out has a $1800 value.</p>
                    <p className="bullet-item">If you hold two (2) Foxes, you will be elegible to claim a free Hugo the Horse NFT, just pay gas! </p>
                    <p className="bullet-item">If you mint an immortal EGO Fox you will be elegible to receive a free NFT from our phase 2 collection.</p>
                    <p className="bullet-item">If you hold the total amount of EGO Fox that are able to be minted (10 Foxes) you get immediate Whitelist access to Eigleer’s upcoming original NFT collection a.k.a phase 2.</p>
                    <p className="bullet-item">1 ETH giveaway raffle to be distributed among 10 winners, must be EGO Fox holders!</p>
                </div>
            </li>

            <li className="roadmap-item">
                <div className="oval-shape" style={{border: '3px solid #f9a8d4'}}>
                    <span>3</span>
                </div>
                <div className="roadmap-desc roadmap-desc-front">
                    <h1 className="text-pink-300">ZOMBIES!</h1>
                    <p>There will be 100 EGO Fox zombies, if you mint one you will automatically receive a WL spot for our phase 2 project!</p>
                </div>
            </li>

            <li className="roadmap-item">
                <div className="oval-shape" style={{border: '3px solid #6de6b7'}}>
                    <span>4</span>
                </div>
                <div className="roadmap-desc roadmap-desc-front">
                    <h1 className="text-green-300">MINT! MINT! MINT!</h1>
                    <p>From the EGO Fox founder’s personal profit, we will do a 15% donation to the Operation Smile organization which helps children and individuals all across the globe receive cleft lip and palate repair procedures which would otherwise not be possible for them to receive. This organization was chosen by our artist due to a personal, sentimental connection to the cleft community.</p>
                </div>
            </li>

            <li className="roadmap-item">
                <div className="oval-shape" style={{border: '3px solid #ec4899'}}>
                    <span>5</span>
                </div>
                <div className="roadmap-desc roadmap-desc-front">
                    <h1 style={{color: '#ec4899'}}>EGO-BOTS arriving!</h1>
                    <p>Still developing this project’s roadmap but we are planning to incorporate an EGO world into the Metaverse!</p>
                </div>
            </li>


        
        </ul>

                
    
    </div>);
}