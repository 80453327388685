import { Toaster } from 'react-hot-toast';
import { useEffect, useState, useGlobal } from 'reactn';
import { mintHorse, connectWallet, formatAddress } from './lib/blockchain';

import AOS from 'aos';
import 'aos/dist/aos.css';

import FAQ from './components/FAQ';
import Header from './components/Header';
import Hero from './components/Hero';
import Marquee from './components/Marquee';
import Roadmap from './components/Roadmap';
import Team from './components/Team';

// const whitelistAddresses = require('./whitelist.json');
// const { MerkleTree } = require('merkletreejs');
// const SHA256 = require('crypto-js/sha256');


function App() {

    const [fox1id, SetFox1id] = useState('');
    const [fox2id, SetFox2id] = useState('');
    const [address] = useGlobal('address');

    const onClickConnect = async () => {
        var connected = await connectWallet();
        if(!connected) return;
    }
    
    useEffect(() => {
        AOS.init();
    }, []);
    
    return (
        <div className="App" id="scroll-top">

            <Toaster toastOptions={{ style: { color: '#000', fontWeight: 'bold' }, }} />

            <Header />

            <Hero />

            <div className="section py-20 md:py-32" id="section-about" data-aos="fade-up" data-aos-duration="1000" style={{backgroundColor: '#403f41'}}>
                
                <div className="text-center mb-32">
                    <button className="hero-cta white" onClick={e => window.open(process.env.REACT_APP_DISCORD, '_blank', 'noopener,noreferrer') }>Join our discord</button>
                </div>

                <div className="col-1 relative">
                    <h2 className="text-center">EGO FOX</h2>
                    <div>
                        <p>
                            Ego Fox is a 3D character project sculpted from scratch by Eigleer Nunes.<br />
                            All unique, only one of each, varying in style and personality; whether paying tribute to an item, icon, cultural motif or anything that creates a distinctive persona for each Fox. 
                            <br /><br />
                            Each character is an artistic contribution to the theme, trait, or persona it embodies, hence why our project’s name is inspired by the definition of alter ego, an alternative self or personality.<br />
                            Ego Fox are 1500 exclusive collectible 3D sculpted characters backed by non-fungible tokens (NFTs) on the Ethereum blockchain and hosted on IPFS.
                            <br /><br />
                            Our Ego Fox project intends to donate 15% of our founder’s personal profit to an organization dedicated to performing cleft lip and cleft palate surgeries for individuals in underprivileged conditions, the organization name is Operation Smile. 
                            <br /><br />
                            The Ego Fox artist, Eigleer Nunes had his first son in 2021 who was born with cleft lip; Eig chose this organization to show his appreciation for the circumstances he lives in in North America where surgery for his son’s lip was so readily available and easily accessible, and acknowledge that although that is not always the case for everyone born with similar conditions, we can still find ways to help out. 
                            <br /><br />
                            We live in a world filled with prejudice and exclusivities, and we believe in being a part of the solution. We would be honored to be able to see more people smile without shame thanks to the effort we have and continue to put into this project.
                        </p>
                    </div>
                    
                </div>
                
            </div>

            <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                <Marquee />
            </div>

            <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" id="section-mint">
                <div className="pt-32 pb-28" style={{borderBottom: '1px solid #333'}}>
                    <div className="text-center">
                        <h2 className="text-center">Mint</h2>
                        <div className="mt-12">
                            We are sold out!<br /><br />
                            <button className="hero-cta white" onClick={e => window.open('https://opensea.io/collection/egofox', '_blank', 'noopener,noreferrer') }>Buy on OpenSea</button>
                        </div>
                    </div>
                </div>
            </div>


            <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" id="section-mint">
                <div className="pt-32 pb-28" style={{borderBottom: '1px solid #333'}}>
                    <div className="text-center">
                        <h2 className="text-center">Hugo the Horse</h2>
                        <div className="mt-12">
                            You can mint a horse for every two foxes you own!<br />
                            Please enter the IDs of your foxes:<br /><br />
                            <div className="flex items-center justify-center pb-12">
                                <div className="p-2">
                                    <input className="rounded p-2 text-black" onChange={e => SetFox1id(e.target.value)} type="text" value={fox1id} placeholder="Fox 1 ID" />
                                </div>
                                <div>
                                    and
                                </div>
                                <div className="p-2">
                                    <input className="rounded p-2 text-black" onChange={e => SetFox2id(e.target.value)} type="text" value={fox2id} placeholder="Fox 2 ID" />
                                </div>
                            </div>
                            {!!address &&
                                <div className="mt-0 text-center">
                                    <div className="mb-4">{formatAddress(address)}</div>
                                    <button className="hero-cta" onClick={e => mintHorse(fox1id, fox2id)}>Mint a horse!</button>
                                </div>
                            }
                            {!address &&
                                <button className="hero-cta" onClick={e => onClickConnect()}>Connect wallet</button>
                            }
                        </div>
                    </div>
                </div>
            </div>


            <div className="section pt-20 md:pt-40" data-aos="zoom-in" data-aos-delay="400" data-aos-duration="1000" id="section-roadmap">
                <div className="col-1">
                    <h2 className="text-center">Roadmap</h2>
                    <Roadmap />
                </div>
            </div>

           
            <div className="section section-team pt-12 md:pt-40" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" id="section-team">
                <div className="col-1">
                    <h2 className="text-center mb-20">The team</h2>
                    <Team />
                </div>
            </div>

            <div className="section pt-12 md:pt-40" data-aos-delay="400" data-aos="fade-up" data-aos-duration="1000" id="section-faq">
                <div className="col-1">
                    <h2 className="text-center">F.A.Q.</h2>
                    <FAQ />
                </div>
            </div>
            
            <div className="footer py-40">
                <div className="text-center text-gray-500">
                    <img src="/logo-text.png" style={{width: 150, margin: '0 auto 20px auto', opacity: 0.5}} />
                    Project not affiliated with any third party.<br /><br />
                    14285 SW 42nd St<br />
                    Unit 204<br />
                    Miami, FL 33175<br />
                    USA<br />
                    <br />
                    All Rights Reserved 2022.<br />
                    {/* <a href="/terms.pdf" target="_blank">Terms and conditions</a>
                    <br /><br />
                    0x123321312312312312321323232 */}
                </div>
            </div>
            
            <canvas id="confetti"></canvas>
        </div>
    );

}

export default App;