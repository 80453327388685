import { setGlobal, getGlobal } from 'reactn';

import { ethers, ContractFactory } from 'ethers';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

import abi from './abi.json';
import abiHorses from './abiHorses.json';
import bytecode from './bytecode.json';
import toast from 'react-hot-toast';
import whitelist from './whitelist';
import JSConfetti from 'js-confetti';

const jsConfetti = new JSConfetti();

const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');


const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider, // required
        options: {
            infuraId: process.env.REACT_APP_INFURA_ID // required
        }
    },
};


const web3Modal = new Web3Modal({
    cacheProvider: false, // optional
    theme: "dark",
    providerOptions, // required
    disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
});


export function formatAddress(address) {
    return address.substring(0, 6) + '...' + address.substring(38, 42);
}



export function networkIdToName(id){
    if(parseInt(id) === 1) return 'Ethereum mainnet';
    if(parseInt(id) === 4) return 'Rinkeby';
    if(parseInt(id) === 3) return 'Ropsten';
    if(parseInt(id) === 5) return 'Goerli';
    if(parseInt(id) === 42) return 'Kovan';
}



export async function connectWallet(){

    try {
        web3Modal.clearCachedProvider();
        var web3modalInstance = await web3Modal.connect();
    } catch(e) {
        console.log("Could not get a wallet connection", e);
        return false;
    }

    window.provider = new ethers.providers.Web3Provider(web3modalInstance);
    window.signer = window.provider.getSigner();

    // Subscribe to network/accounts change
    window.provider.on('chainChanged', id => {
        document.location.reload();
    });
    
    window.provider.on('accountsChanged', function (accounts) {
        document.location.reload();
    });

    var accounts = await window.provider.listAccounts();
    var network = await window.provider.getNetwork();
    var chainId = parseInt(network.chainId);

    await setGlobal({ address: accounts[0] });
    await setGlobal({ chainId: chainId });

    return true;
}

export async function mintHorse(fox1id, fox2id){
    
    // call mint from contract
    var contract = new ethers.Contract(process.env.REACT_APP_HORSES_CONTRACT_ADDRESS, abiHorses, window.provider);
    var contractWithSigner = contract.connect(window.signer);
    

    console.log('minting...');
    
    if(!Number.isInteger(parseInt(fox1id)) || !Number.isInteger(parseInt(fox2id))){
        toast.error('Fox id not valid');
        return;
    }

    // listen to a contract event when the transaction is finished
    contract.once("Minted", (caller, event) => {
        if(caller !== getGlobal().address) return;
        setGlobal({minting: false});
        // toast.success('Mint successful!');
    });

    
    const toastId = toast.loading('Minting...');
    setGlobal({minting: true});

    try{
        var tx = await contractWithSigner.redeem(fox1id, fox2id, {from: getGlobal().address});
        console.log('tx:', tx);
        if(tx.wait) await tx.wait();    
        
        toast.dismiss(toastId);
        toast.success('Minting successful!');
        setGlobal({minting: false});
        jsConfetti.addConfetti();

    }catch(e){

        toast.dismiss(toastId);
        setGlobal({minting: false});
        showError(e);

    }
}

export async function fetchMintingData(){
   
    if(getGlobal().chainId != process.env.REACT_APP_NETWORK) return ['wrong network', 0];
    
    // retrieve contract info
    var contract = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, abi, window.provider);
    var totalSupply = await contract.totalSupply();
    var maxSupply = await contract.maxSupply();
    var paused = await contract.paused();
    var usingWhitelist = await contract.usingWhitelist();
    var mintCase;

    // determine minting case
    if(paused) mintCase = 'paused';
    else if(totalSupply == maxSupply) mintCase = 'soldout';
    else if(usingWhitelist) mintCase = 'whitelist';
    else mintCase = 'public';

    // determine price
    var price = await contract.getPricePublic();
    if(usingWhitelist){
        price = await contract.getPriceWL();
    }

    price = ethers.utils.formatEther(price);

    var mintingData = {
        totalSupply: parseInt(totalSupply),
        maxSupply: parseInt(maxSupply),
        paused: paused,
        price: price,
        usingWhitelist: usingWhitelist,
        left: maxSupply - totalSupply,
        mintCase: mintCase
    }

    await setGlobal({ mintingData: mintingData });    

}


export function isAddressWhitelisted(){
    if(!getGlobal().address || getGlobal().address === '') return false;
    var address = getGlobal().address;
    return whitelist.includes(address);
}

export async function mintWhitelist(qty, price){
    
    if(!isAddressWhitelisted()) return false;
    
    const leafNodes = whitelist.map(addr => keccak256(addr));
    const tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true});
    const root = tree.getRoot().toString('hex');
    
    console.log(root);
    
    const claimingAccount = leafNodes[whitelist.indexOf(getGlobal().address)];
    const hexProof = tree.getHexProof(claimingAccount);
    
    var contract = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, abi, window.provider);
    var contractWithSigner = contract.connect(window.signer);

    // listen to a contract event when the transaction is finished
    contract.once("Minted", (caller, event) => {
        if(caller !== getGlobal().address) return;
        // addToMetamask(contractAddress, symbol);
        setGlobal({minting: false});
        // toast.success('Mint successful!');
    });

    var amount = qty * price;
    amount = amount.toFixed(2);
    
    const toastId = toast.loading('Minting...');
    setGlobal({minting: true});

    try{

        var tx = await contractWithSigner.mintWL(qty, hexProof, {from: getGlobal().address, value: ethers.utils.parseUnits(amount, "ether").toHexString()});
        console.log('tx:', tx);
        if(tx.wait) await tx.wait();    
        
        toast.dismiss(toastId);
        toast.success('Minting successful!');
        setGlobal({minting: false});
        jsConfetti.addConfetti();
        fetchMintingData();
        
    }catch(e){

        toast.dismiss(toastId);
        setGlobal({minting: false});
        showError(e);
        
    }

}


export async function mint(qty, price){
    
    qty = parseInt(qty);
    
    // call mint from contract
    var contract = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, abi, window.provider);
    var contractWithSigner = contract.connect(window.signer);
    var maxPerTx = await contract.maxPerTxPublic();

    console.log('minting...');

    if(!Number.isInteger(qty) || qty <= 0){
        toast.error('Quantity not valid');
        return;
    }

    if(qty > maxPerTx){
        toast.error('Sorry, max ' + maxPerTx + ' per transaction');
        return;
    }

    // listen to a contract event when the transaction is finished
    contract.once("Minted", (caller, event) => {
        if(caller !== getGlobal().address) return;
        setGlobal({minting: false});
        // toast.success('Mint successful!');
    });

    
    var amount = qty * price;
    amount = amount.toFixed(2);
    
    const toastId = toast.loading('Minting...');
    setGlobal({minting: true});

    try{
        var tx = await contractWithSigner.mintPublic(qty, {from: getGlobal().address, value: ethers.utils.parseUnits(amount, "ether").toHexString()});
        console.log('tx:', tx);
        if(tx.wait) await tx.wait();    
        
        toast.dismiss(toastId);
        toast.success('Minting successful!');
        setGlobal({minting: false});
        jsConfetti.addConfetti();
        fetchMintingData();

    }catch(e){

        toast.dismiss(toastId);
        setGlobal({minting: false});
        showError(e);

    }


}



function showError(e){

    console.log(e);
    
    if(e.data && e.data.message){
        toast.error(e.data.message.replace('execution reverted: ', ''));
        return;
    }

    if(e.error && e.error.message){
        toast.error(e.error.message.replace('execution reverted: ', ''));
        return;
    }

    toast.error('Mint failed, please try again');

}






export async function remainingWithoutMinting(){
    var remaining = {};
    var issues = getGlobal().issues;
    if(!issues) return;
    for(var issue of issues){
        var contract = new ethers.Contract(issue.contractAddress, abi, window.provider);
        if(issue.contractAddress == process.env.REACT_APP_GENESIS_CONTRACT) return 0;
        try{
            var left = await contract.remaining();
            remaining[issue.contractAddress] = parseInt(left);
        }catch(e){
            console.log('error getting remaining');
            console.log(e);
        }
    };
    setGlobal({remaining: remaining});
}



export async function deployContract(name, symbol, baseURI, price){
    

    setGlobal({contractAddress: null});
  
    var factory = new ContractFactory(abi, bytecode, window.signer);
    var contract = await factory.deploy(name, symbol, baseURI, ethers.utils.parseUnits(price, "ether"));
    // var contract = await factory.deploy();
    await contract.deployed();
    await setGlobal({contractAddress: contract.address});
    
    return contract.address;

}
