import './Team.css';

export default function Team(props){
    return (
        <div className="team-members md:grid grid-cols-3">
            
            <div className="team-member">
                <div className="team-member-image">
                    <img src="/team/1.jpg" />
                </div>
                <h3>Eigleer</h3>
                <h4>Mastermind</h4>
            </div>

            <div className="team-member">
                <div className="team-member-image">
                    <img src="/team/2.jpg" />
                </div>
                <h3>Adri</h3>
                <h4>Fashion designer</h4>
            </div>

            <div className="team-member">
                <div className="team-member-image">
                    <img src="/team/3.jpg" />
                </div>
                <h3>Agu</h3>
                <h4>Web3 Developer</h4>
            </div>

            <div className="team-member">
                <div className="team-member-image">
                    <img src="/team/4.jpg" />
                </div>
                <h3>Ferre</h3>
                <h4>Community manager</h4>
            </div>

            <div className="team-member">
                <div className="team-member-image">
                    <img src="/team/5.jpg" />
                </div>
                <h3>Hono</h3>
                <h4>Community manager</h4>
            </div>

            <div className="team-member">
                <div className="team-member-image">
                    <img src="/team/6.jpg" />
                </div>
                <h3>Arvin</h3>
                <h4>Marketing</h4>
            </div>

            


        </div>
    )
}