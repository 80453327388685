import { useEffect } from 'react';
import './Hero.css';
import Countdown from './Countdown';
import $ from 'jquery';
window.$ = $;

export default function Hero(props){

    useEffect(() => {
        
        // window.TweenMax.to('.deco-left', 0.2, {
        //     scale: 1,
        //     opacity: 1,
        //     onComplete: () => { console.log('test') },
        //     ease: window.Elastic.easeInOut,
        // });

        // window.TweenMax.to('.deco-right', 0.2, {
        //     scale: 1,
        //     opacity: 1,
        //     onComplete: () => { console.log('test') },
        //     ease: window.Elastic.easeInOut
        // });

        // fog
        // if(!document.querySelector('#fog-script')){
        //     console.log('fog script not found');
        //     const script = document.createElement("script");
        //     script.src = '/fog.js';
        //     script.async = true;
        //     script.id = 'fog-script';
        //     // document.body.appendChild(script);
        // }

    }, []);


    const scrollTo = (dest) => {                
        if($(dest).length == 0) return;
        window.TweenMax.to('html,body', 0.6, {
            scrollTop: $(dest).offset().top - 140,
            ease: window.Power4.easeInOut
        });
    }
    

    return (<div className="hero">
                
        <div className="hero-content">
            
            <div className="hero-logo">
                <div data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">

                    {/* <Countdown date={'Jan 22, 2022 00:00:00'} /> */}
                    
                    <div className="text-center">
                        <img src="/logo-text.png" style={{width: '100%', maxWidth: 600}} />
                    </div>
                    
                </div>
            </div>

        </div>

        <div className="hero-deco"></div>

        <div className="hero-overlay"></div>

        <div className="hero-image">
            <canvas className="p-canvas-webgl" id="canvas-webgl"></canvas>
        </div>

    </div>)

}