import './FAQ.css';
import ReactFaq from 'react-faq-component';

const data = {
    rows: [
      {
        title: "What is an NFT?",
        content: "NFT stands for non-fungible token, an NFT is a unique piece of digital art stored in the blockchain that you can own, sell or buy"
      },
      {
        title: "How much is a Ego Fox NFT?",
        content: "The price per Ego Fox will be 0.05 ETH for public sale. Plus gas. "
      },
      {
        title: "How many Ego Foxes will there be available?",
        content: "1500 total foxes will be available to mint."
      },
      {
        title: "Where can I view my Ego Fox NFTs?",
        content: "Once you have minted a fox, you will be able to see it by connecting your crypto wallet to OpenSea."
      },
      {
        title: "Is there a limit to mint?",
        content: "Yes, there is a limit of 10 Ego Foxes per wallet."
      },
      {
        title: "Do I own my Ego Fox after minting it?",
        content: "You will own complete copyrights on your NFT, and you'll be able to use it as an avatar to flex it on your social media or even use it to create merchandise."
      },
      {
        title: "What blockchain are the Ego Foxes on?",
        content: "The Ethereum blockchain."
      },
      {
        title: "Are Ego Foxes a good investment?",
        content: "Yes! We are continuously working to make sure each Ego Fox has a long, fruitful life ahead of them, and will continue to grow since we have further plans for our project. We hope for the Ego Fox project to make it to the moon, although uncertain still like any new endeavor in life and business."
      },
      {
        title: "Is Ego Fox affiliated with any other projects?",
        content: "No, currently we are a fully independent team that has no affiliations with any other projects. "
      },
    ]
  }

export default function FAQ(props){
    return (
        <ReactFaq
        data={data}
        styles={{
            titleTextColor: "white",
            rowTitleColor: "rgb(129,140,248)",
            rowContentColor: "rgba(255, 255, 255, 0.4)",
            bgColor: "transparent",
            arrowColor: "rgb(129,140,248)",
        }} 
        />
    );
}