import React, { useEffect, useState } from "react";
import times from "lodash/times";
import Marquee from "react-marquee-slider";
import { withSize } from "react-sizeme";
import { nanoid } from "nanoid";

var image_width = 368;
var image_height = 500;
var scale = 0.5;

const photos = [
  '/avatars/1.jpg',
  '/avatars/2.jpg',
  '/avatars/3.jpg',
  '/avatars/4.jpg',
  '/avatars/5.jpg',
  '/avatars/6.jpg',
];

const ImageMarquee = ({ size }) => {

  const [key, setKey] = useState(nanoid());

  useEffect(() => {
        
    setKey(nanoid());

  }, [size, size.width]);


  if (size && size.width > 800) {
    scale = 0.65;
  }

  if (size && size.width > 1100) {
    scale = 0.8;
  }

  if (size && size.width > 1400) {
    scale = 1;
  }

  var styles = {
        image: {
            width: scale * image_width,
            height: scale * image_height,
            borderRadius: 4,
            boxShadow: '0 7px 20px 0 rgba(0, 0, 0, 0.12)',
            objectFit: 'cover',
            objectPosition: 'top',
            marginLeft: scale * 47,
            marginRight: 0,
        }
    }

  return (
    
    <div style={{ marginTop: 0, height: scale * image_height, width: '100vw' }}>
        <Marquee key={key} velocity={25}>
            {times(6, Number).map((id) => (
                <img style={styles.image} src={photos[id]} alt="" key={id} />
            ))}
        </Marquee>
    </div>

  );
};



export default withSize()(ImageMarquee);



